$bg-color: #f7f9fa;
$text-color: #222;
$grey-color: #4a4f51;
$green-color: #1eb950;

:root {
  --color-CM: #407EC9;
  --color-CIR: #01C3AA;
  --color-PED: #FFC649;
  --color-PREV: #3B3FB6;
  --color-GO: #AC145A;
  --color-SEM-ESP: #00205B;
}


@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');

html,
body {
  background-color: $bg-color;
  font: 400 14px/28px 'MontSerrat', sans-serif;
  letter-spacing: 0px;
  color: $text-color;
  overflow-x: hidden;
}

.w-20 {
  width: 20%;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-fill {
  flex: 1;
}

.flex-strech-self {
  align-self: stretch;
}

.size-25 {
  width: 25px;
  height: 25px;
}

.primary-color {
  color: $text-color;
}

.grey-color {
  color: $grey-color;
}

.green-color {
  color: $green-color;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


//////////////////
// FULL LOADING //
//////////////////
.full-loading {
  height: 100vh;
  z-index: 999;
  position: absolute;
  top: 0;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;

  img {
    width: 100px;
  }
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;

  &.show-loading {
    visibility: visible;
    opacity: 1;
  }

  &.hide-loading {
    visibility: hidden;
    opacity: 0;
  }
}

.gallery{
  width: 60%;
  margin: auto;
  min-height: 270px;
  border: 1px lightgray dashed;

  &.dragging {
    background-color: #80808008;
  }

  ul {
    padding: 10px;
    margin: 0;
  }

  li {
    margin: 2px;
    cursor: pointer;
    border: 1px lightgray solid;
    width: 120px;
    height: 120px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    float: left;

    &:hover {
      background: #F3F3F3;

      .remove-icon{
        opacity: 1;
      }
    }
    
    &.new-image {
      text-align: center;
      color: lightgray;
      div {
        font-size: 38px;
        margin-top: 33px;
      }
    }
    img {
      max-width: 100%;
    }
  }
};

.remove-icon {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 14px;
  padding: 5px;
  text-align: center;
}
